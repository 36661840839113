import type { NineSubmitEvent } from "@9amhealth/wcl/generated/events/events";
import {
  NineActions,
  NineButton,
  NineForm,
  NineHeading,
  NineInput
} from "@9amhealth/wcl/generated/react";
import type { FC } from "react";
import React, { useState } from "react";

import FileType from "src/constants/fileType";
import reportErrorSentry from "src/lib/reportErrorSentry";
import translate from "src/lib/translate";
import type {
  CardDetailsFormKey,
  CardDetailsFormValues
} from "src/state/PatientInsuranceBloc/PatientInsuranceBloc";
import PatientInsuranceBloc, {
  AddInsuranceDocuments
} from "src/state/PatientInsuranceBloc/PatientInsuranceBloc";
import { useBloc } from "src/state/state";
import BlockingLoadingOverlayController from "src/ui/components/BlockingLoadingOverlay/BlockingLoadingOverlayController";
import DocumentUploader from "src/ui/components/DocumentUploader/DocumentUploader";
import Translate from "src/ui/components/Translate/Translate";

export enum AddPharmacyInsuranceInfoAction {
  ADD_CARD_DETAILS = "add-card-details",
  ADD_CARD_PHOTOS = "add-card-photos"
}

const MultiStepPharmacyInsuranceForm: FC<{
  onInsuranceSaved?: () => void;
  onCanceled?: () => void;
}> = ({ onInsuranceSaved, onCanceled }) => {
  const [
    ,
    {
      addInsuranceFormFields,
      temporaryCardDetailFormValues,
      temporaryCardDetailFiles,
      savePharmacyInsurance
    }
  ] = useBloc(PatientInsuranceBloc);
  const [allFilesSelected, setAllFilesSelected] = React.useState(false);
  const [step, setStep] = React.useState<AddPharmacyInsuranceInfoAction>(
    AddPharmacyInsuranceInfoAction.ADD_CARD_DETAILS
  );
  type InsuranceError = Record<CardDetailsFormKey, string>;
  type InsuranceValidation = Record<CardDetailsFormKey, RegExp>;

  const [errors, setErrors] = useState<Record<string, string>[]>([]);

  const insuranceFieldsValidation: InsuranceValidation = {
    cardHolderId: /^.{1,20}$/,
    rxPcn: /^.{1,10}$/,
    rxBin: /^.{1,8}$/,
    rxGrp: /^.{1,15}$/
  };

  const insuranceErrorList: InsuranceError = {
    cardHolderId: translate("cardHolderId_invalid"),
    rxPcn: translate("rxPcn_invalid"),
    rxBin: translate("rxBin_invalid"),
    rxGrp: translate("rx_Grp_invalid")
  };

  const handleChange = (event: CustomEvent, fieldName: CardDetailsFormKey) => {
    const value: string = event.detail.value;
    const correctInputValue =
      !!insuranceFieldsValidation[fieldName].exec(value);

    const errorObj: { [key in CardDetailsFormKey]?: string } = {};
    errorObj[fieldName] = insuranceErrorList[fieldName];
    const otherInputsErrors = errors.filter((error) => !(fieldName in error));

    if (!correctInputValue) {
      setErrors([...otherInputsErrors, errorObj]);
    } else {
      setErrors(otherInputsErrors);
    }
  };

  const handleFormSubmit = (e: NineSubmitEvent<CardDetailsFormValues>) => {
    temporaryCardDetailFormValues.current = e.detail;
    setStep(AddPharmacyInsuranceInfoAction.ADD_CARD_PHOTOS);
  };

  const handleFileUploadChange = (
    fileIds: Record<AddInsuranceDocuments, string>,
    allSelected: boolean
  ) => {
    setAllFilesSelected(allSelected);
    temporaryCardDetailFiles.current = fileIds;
  };

  const handleSaveCard = () => {
    if (
      !temporaryCardDetailFormValues.current ||
      !temporaryCardDetailFiles.current ||
      !allFilesSelected
    ) {
      return;
    }

    BlockingLoadingOverlayController.startLoading({
      bg: "transparent"
    });
    void savePharmacyInsurance(
      temporaryCardDetailFormValues.current,
      temporaryCardDetailFiles.current
    )
      .then(() => {
        BlockingLoadingOverlayController.loadingSuccess();
        onInsuranceSaved?.();
        setAllFilesSelected(false);
        temporaryCardDetailFormValues.current = null;
        temporaryCardDetailFiles.current = null;
      })
      .catch((e: unknown) => {
        reportErrorSentry(e);
        BlockingLoadingOverlayController.loadingError({
          retry: () => {
            handleSaveCard();
          },
          onCancel: () => {
            setAllFilesSelected(false);
            temporaryCardDetailFormValues.current = null;
            temporaryCardDetailFiles.current = null;
            onCanceled?.();
          }
        });
      });
  };

  return (
    <>
      {step === AddPharmacyInsuranceInfoAction.ADD_CARD_DETAILS && (
        <div>
          <NineHeading>
            <h3 className="as-h4-large">
              <Translate msg="insurance.enterDetails.title" />
            </h3>
            <nine-spacer s="md"></nine-spacer>
            <p className="m0 color-c-80">
              <Translate msg="insurance.enterDetails.description" />
            </p>
          </NineHeading>
          <nine-spacer s="lg"></nine-spacer>

          <NineForm
            style={{ padding: "0 0.6rem" }}
            onNineSubmit={handleFormSubmit}
          >
            <>
              {addInsuranceFormFields.map((field) => {
                const errorForInput = errors.find(
                  (error) => field.name in error
                );
                const error = errorForInput ? errorForInput[field.name] : "";
                return (
                  <NineInput
                    key={field.name}
                    name={field.name}
                    label={translate(field.label)}
                    onNineChange={(event) => {
                      handleChange(event, field.name);
                    }}
                    error={error}
                    required="true"
                  />
                );
              })}
            </>
            <NineActions style={{ justifyContent: "center" }}>
              <NineButton
                disabled={errors.length > 0 ? "true" : undefined}
                type="submit"
              >
                <Translate msg="continue" />
              </NineButton>
            </NineActions>
          </NineForm>
        </div>
      )}

      {step === AddPharmacyInsuranceInfoAction.ADD_CARD_PHOTOS && (
        <div>
          <NineHeading>
            <h3 className="as-h4-large">
              <Translate msg="insurance.uploadPhoto" />
            </h3>
          </NineHeading>
          <nine-spacer s="lg"></nine-spacer>
          <DocumentUploader
            config={{
              fileTypes: ["image/jpeg", "image/png"],
              files: {
                [AddInsuranceDocuments.FRONT]: {
                  title: translate("update.frontSide"),
                  tags: ["pharmacy-insurance-card-front"],
                  namePrefix: "Pharmacy Insurance Card Front",
                  metaFileType: FileType.PHARMACY_INSURANCE_CARD
                },
                [AddInsuranceDocuments.BACK]: {
                  title: translate("update.backSide"),
                  tags: ["pharmacy-insurance-card-back"],
                  namePrefix: "Pharmacy Insurance Card Back",
                  metaFileType: FileType.PHARMACY_INSURANCE_CARD
                }
              }
            }}
            onChange={handleFileUploadChange}
          />

          <NineActions style={{ justifyContent: "center" }}>
            <NineButton
              disabled={allFilesSelected ? "false" : "true"}
              onClick={handleSaveCard}
            >
              <Translate msg="confirm" />
            </NineButton>
          </NineActions>
        </div>
      )}
    </>
  );
};
export default MultiStepPharmacyInsuranceForm;
